/* styles.css */
.navBar {
    scrollbar-width: thin;
    scrollbar-color: #888 #333;
}

.navBar::-webkit-scrollbar {
    width: 12px;
}

.navBar::-webkit-scrollbar-track {
    background: #333;
}

.navBar::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 20px;
    border: 3px solid #333;
}